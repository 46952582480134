import { getRequest, postRequest } from './index'
import { SRCURL } from '@/systemConfiguration/index'

// 加工厂分页列表查询
export const managerListPageFactory = (data, successCallback, failureCallback) => {
  postRequest(`${SRCURL}/operate/factory/manager/listPageFactory`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 审核加工厂
export const managerReviewFactoryBusiness = (data, successCallback, failureCallback) => {
  postRequest(`${SRCURL}/operate/factory/manager/reviewFactoryBusiness`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 开启加工厂
export const managerOpenFactory = (id, successCallback, failureCallback) => {
  postRequest(`${SRCURL}/operate/factory/manager/openFactory/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 关闭加工厂
export const managerCloseFactory = (data, successCallback, failureCallback) => {
  postRequest(`${SRCURL}/operate/factory/manager/closeFactory`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 查询加工厂详细信息
export const managerQueryFactoryInfoDesc = (id, successCallback, failureCallback) => {
  getRequest(`${SRCURL}/operate/factory/manager/queryFactoryInfoDesc/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 查询加工单
export const managerListPageFactoryOrder = (data, successCallback, failureCallback) => {
  postRequest(`${SRCURL}/operate/factory/manager/listPageFactoryOrder`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 查询加工单详情
export const queryFactoryOrderDesc = (id, successCallback, failureCallback) => {
  getRequest(
    `${SRCURL}/trade/factory/order/queryFactoryOrderDesc/${id}`,
    {},
    (res) => successCallback(res),
    (error) => {
      if (failureCallback) failureCallback(error)
    }
  )
}

